
.shortCut-container {
    max-width: 100px;
    max-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    white-space: normal;
    padding: 0px 10px;
    filter: drop-shadow(0px 0px 20px var(--drop-filter-var));
    .shortCut-icon {
        max-width: 100px;
        height: 100px;
        // width: 50px;
        // height: 50px;
        color: var(--primary-color);
        opacity: 100%;
        
        filter: var(--filter-var);
    }
    .shortCut-text {
        max-width: 100px;
        height: fit-content;
        white-space: normal;
        word-break: break-all;
    }
}