

.buddy-outer {
    position: fixed;
    top: 125px;
    left: 5vw;
    background-color: gray;
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 200px;
    min-width: 350px;
    min-height: 300px;
    
    .buddy-content {
        width: 100%;
        height: 100%;
        overflow: auto;
        scroll-behavior: smooth;
        scrollbar-width: thin; /* Thin scrollbars for Firefox */
        scrollbar-color: var(--scrollbar-thumb-color, var(--primary-color)) var(--scrollbar-track-color, #000000); /* Default colors */
    
        &::-webkit-scrollbar {
            width: 15px; /* Same width as the default setting */
        }
    
        &::-webkit-scrollbar-track {
            background: var(--scrollbar-track-color, #000000); /* Default track color */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: var(--scrollbar-thumb-color, var(--primary-color)); /* Default thumb color */
            border-radius: 30px;
        }
    
        &::-webkit-scrollbar-thumb:hover {
            background-color: var(--scrollbar-thumb-hover-color, var(--primary-color)); /* Hover state for thumb */
        }
    }
    

    #terminalEditor {
        padding-bottom: 5px;
    }
}