
.topMenu-container {
    display: flex;
    flex-direction: row;
    .topMenu-leftContent {
        align-self: flex-start;
        margin-left: 10px;
        margin-right: auto;
        justify-content: center;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 20px;
        color: var(--primary-accent);
        text-shadow: 0 0 5px var(--primary-standout);
    }
    .topMenu-rightContent-topBar {
        align-self: flex-start;
        margin-right: 10px;
        margin-left: auto;
        justify-content: center;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 20px;
        color: var(--primary-accent);
        text-shadow: 0 0 5px var(--primary-standout);
    }
    .topMenu-rightContent {
        align-self: flex-end;
        margin-right: 10px;
        margin-left: auto;
        justify-content: center;
        height: 100%;
        display: flex;
        align-items: center;
        .buddy-topBarControlBox {
            font-weight: 900;
            cursor: pointer;
            margin-left: 6px;
            margin-right: 6px;
        }
        .buddy-topBarControlBox:hover {
            font-weight: 900;
            text-shadow: 0 0 7px white, 0 0 15px white;
            transition: 0.6s;
        }
    }
}