

.main-container {
    background-color: black;
    // height: 100%;
    height: 100vh;  
    max-height: -webkit-fill-available;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .main-outerLines {
        height: 97%;
        width: 97vw;
        outline: thick solid var(--primary-color);
        border-radius: 5px;
        box-shadow: 0 0 30px var(--primary-color);
        // outline: thick solid #00c1da;
        // border-radius: 5px;
        // box-shadow: 0 0 30px #00c1da;
    }
}

