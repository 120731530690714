
.resume-container {
    color: var(--primary-color);
    padding: 8px;
    background-color: rgb(10, 9, 9);
}

a {
    color: var(--primary-color);
    text-emphasis: none;
}

h1, h2, h3 { 
    margin-block-end: 0;
    margin-block-start: 0; 
}
p {
    margin-block-end: 0;
    margin-block-start: 0; 
}

