.buddy-topBar {
    background-color: var(--primary-color);
    height: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    .buddy-topBarContent {
        @mixin text-outline($color: #000, $width: 1px) {
            text-shadow:
              #{$width} #{$width} 0 $color,
              #{-$width} #{$width} 0 $color,
              #{$width} #{-$width} 0 $color,
              #{-$width} #{-$width} 0 $color;
          }
        align-self: flex-start;
        margin-left: 8px;
        margin-right: auto;
        justify-content: center;
        height: 100%;
        display: flex;
        align-items: center;
        color: var(--primary-accent);
        font-size: 20px;
        @include text-outline(#000, 1px);
    }
    .buddy-topBarControls {
        align-self: flex-end;
        margin-right: 10px;
        margin-left: auto;
        justify-content: center;
        height: 100%;
        display: flex;
        align-items: center;
        .buddy-topBarControlBox {
            font-weight: 900;
            cursor: pointer;
            margin-left: 6px;
            margin-right: 6px;
        }
        .buddy-topBarControlBox:hover {
            font-weight: 900;
            text-shadow: 0 0 7px white, 0 0 15px white;
            transition: 0.6s;
        }
    }
}

