$screen-sm-min: 576px;

.system-mainContainer {
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 1;
    .topMenu-container {
        position: absolute;
        background-color: var(--primary-color);
        width: 97vw;
        height: 30px;
        z-index: 4;
    }
    .system-shortcuts {
        padding: 60px 40px 60px;
        min-width: 300px;
        max-width: 1000px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;

    }
    .system-backgroundContainer {
        .system-backgroundImage {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            max-width: 300px;
            @media screen and (max-width: 576px) {
                display: none;
            }
        }
        .system-backgroundLoadingBar {

        }
    }
    .bottomMenu-container {
        position: absolute;
        background-color: var(--primary-color);
        width: 97vw;
        height: 30px;
        z-index: 4;
        bottom: 1.5vh;
        @media screen and (max-width: 576px) {
            bottom: 1.3vh;
        }
    }
}